/* You can add global styles to this file, and also import other style files */
@import './font/stylesheet.css';
@import 'assets/css/variables.scss';

* {
  margin: 0;
  outline: none !important;
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

body,
.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography,
.mat-table,
.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5 {
  font-family: $font_family;
  font-size: 14px;
}

.btn.btn-primary {
  font-family: $font_family;
}
.form_control:focus {
  border-color: #000 !important;
}
.astrick::after {
  content: '*';
  color: red;
}
.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base {
  padding-top: 0 !important;
}
.top_header figure {
  cursor: pointer;
}

.mat-header-cell {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
  line-height: 0;
}

.blk_span {
  display: block;
}

.yellowClass {
  color: $yellow-clr;
}

.greenClass {
  color: $green-clr;
}

.redClass {
  color: $red-clr;
}

.con_tainer {
  width: 90%;
  margin: 0 auto;
  max-width: 1290px;
}

.al_sc {
  padding: 60px 0 30px;
}
.btn.btn-primary.pdng {
  padding: 10px;
}
p {
  color: $para-clr;
  margin-top: 5px;
  line-height: normal;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
}

.export_btn .btn-primary {
  margin-left: 10px;
}

.btn.btn-primary {
  background: $primary;
  color: $white-clr;
  font-weight: 400;
  font-size: 15px;
  border-radius: 6px;
}

.btn {
  line-height: normal;
  border: unset;
  box-shadow: unset !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  padding: 12px 20px;
}

.mat-checkbox-input {
  display: none;
}

.top_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 8px rgba(4, 4, 7, 0.1);
  padding: 10px;
  background: $primary;
  position: sticky;
  top: 0;
  z-index: 1024;
}

.top_header mat-icon {
  cursor: pointer;
  color: $white-clr;
}

.top_header_ryt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.top_header_ryt i {
  font-size: 22px;
  cursor: pointer;
  color: $white-clr;
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $white-clr !important;
}
.top_header_ryt figure {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.top_header_ryt figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.dropbox {
  border: 1px solid $border;
  box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
  padding: 10px;
  border-radius: 10px;
  position: absolute !important;
  right: 0px;
  top: 6px;
  z-index: 1;
}

.ntf-list {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid $border;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}

.ntf-list figure {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 0px 5px;
  cursor: pointer;
}

.ntf-list figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ntf_dt {
  margin-left: 10px;
}

.ntf_dt h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  color: $tbl-clr;
}

.ntf_dt p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
}
.sms_cnt {
  margin-top: 20px;
}
mat-dialog-container {
  max-height: 600px !important;
}
.bx {
  width: 32%;
  padding-bottom: 20px;
  line-height: 2;
}
.add_bt {
  display: block;
  margin-left: auto;
}
.nt_typ {
  margin-bottom: 0 !important;
  color: $primary;
}

.ntf_dt p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
}

.ntf_tym {
  font-size: 12px;
  position: absolute;
  right: 0px;
  top: 0;
  margin-bottom: 0;
}

.see_link {
  background: transparent;
  border: 0;
  color: $tbl-clr;
  font-weight: 600;
  cursor: pointer;
}

.dropbox ul li {
  color: $primary;
  padding-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;

  &:last-child {
    padding-bottom: 0;
  }
  .mat-menu-content {
    padding: 0;
  }
}

.title-bar h1 {
  font-size: 18px;
  margin: 20px 0px 30px;
  font-weight: 500;
  color: $primary;
}

.st_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logomain[_ngcontent-aen-c93] figure[_ngcontent-aen-c93] {
  cursor: pointer;
}

.mat-sidenav.mat-drawer .st_logo {
  padding: 17px 0px;
}
.sd_bar .mat-list-item {
  height: 52px !important;
}
.active.mat-list-item {
  background: $white-clr !important;
  border-radius: 0 50px 50px 0;
}
.st_logo {
  max-width: 60%;
  margin: 0 auto;
  width: 100%;
}
.rs_logo {
  max-width: 30px;
}
.mat-drawer-opened .rs_logo {
  display: none;
}
.mat-expansion-panel {
  border-radius: 0 !important;
  background: none;
}
:not(.mat-drawer-opened) .st_logo {
  display: none;
}
.mat-drawer-opened .st_logo {
  display: block;
}
:not(.mat-drawer-opened) .sd_cn {
  margin-right: 0;
}
:not(.mat-drawer-opened) .mat-expansion-panel-body {
  padding-left: 0;
}
.mat-drawer-opened .mat-expansion-panel-body {
  padding-left: 24px;
}
// .mat-drawer-opened:not() .st_logo{
// display: none;
// }
.mat-drawer.mat-drawer-opened {
  width: 15% !important;
  transform: none !important;
  visibility: visible !important;
  border: 0;
  padding-right: 5px;
}

mat-sidenav.mat-drawer {
  display: block !important;
  visibility: visible !important;
  width: 70px !important;
  transform: unset;
}

.mat-drawer {
  background-color: $primary;
}

.mat-list-item h5 {
  margin-bottom: 0 !important;
  color: $white-clr;
  font-weight: 400;
  font-size: 13px;
}

.mat-drawer.mat-drawer-opened
  .mat-list-base
  .mat-list-item
  .mat-list-item-content
  .sd_cn {
  margin-right: 15px;
}

.sd_cn {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.sd_cn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(0) invert(1);
  min-width: 25px;
}

.active .lead {
  color: $primary !important;
  font-weight: 500;
}

.active .sd_cn img {
  filter: brightness(0);
}

.sidenav-item {
  display: none;
}

.mat-drawer.mat-drawer-opened .sidenav-item {
  display: block;
}

mat-sidenav-content.mat-drawer-content.mat-sidenav-content.ng-star-inserted {
  margin-left: 70px;
  transition-duration: 200ms;
}

.mat-sidenav ::-webkit-scrollbar,
.mat-table ::-webkit-scrollbar {
  width: 3px;
  display: none;
}

::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}
.final_status {
  background: #e5ecf3;
  margin-top: 30px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
  box-shadow: unset;
  padding: 20px;
  border-radius: 10px;
  h2 {
    margin-bottom: 0;
    color: $primary;
  }
}
.mat-sidenav ::-webkit-scrollbar-thumb,
.mat-table ::-webkit-scrollbar-thumb {
  background: #91919142;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

.mat-sidenav ::-webkit-scrollbar-track,
.mat-table ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: $lightbg;
}

/*** card design ***/
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white-clr;
  background-clip: border-box;
  border-radius: 10px;
  box-shadow: 0 0 9px $shadow;
  border: 1px solid $border;
}

.card .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.25rem;
}

.filter_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filter_sec {
  margin-bottom: 20px;
}

.mat-form-field .from_control,
.mat-form-field.select,
.srch {
  border: 1px solid $border;
  border-radius: unset;
  padding: 12px 10px;
  font-size: 14px;
  font-weight: 400;
  color: $black-clr;
  background: transparent;
  width: 100%;
  border-radius: 0px;
  line-height: inherit;
}

.filter_lft {
  margin-top: 50px;
}

.filter_lft .mat-form-field-flex {
  align-items: center;
}

.srch {
  padding: 6px 10px;
  border-radius: 6px;
}

.filter_sec
  .mat-form-field-appearance-legacy
  .mat-form-field-prefix
  .mat-icon-button
  .mat-icon {
  font-size: 20px !important;
  font-weight: 600;
}

.filter_lft button {
  background: transparent;
  border: 0;
  vertical-align: top;
  // line-height: 0;
}
.mat-menu-panel {
  max-height: 350px !important;
}

.srch .mat-form-field-infix {
  border-top: 0px;
}

.srch .mat-form-field-wrapper {
  padding-bottom: 0em;
}

.srch .mat-form-field-underline {
  display: none;
}

.geo_tbl mat-table {
  text-align: center;
}

.mat-table {
  overflow-x: auto;
  padding: 0px 0;
  border-spacing: 0 0px;
  border-collapse: separate;
  border: 1px solid $tbl-border;
  border-top: 0;
  border-bottom: 0;
  background: $white-clr;
}

.mat-row,
.mat-header-row {
  display: table-row;
}

.tables .mat-header-cell {
  font-size: 15px;
  border: 1px solid $tbl-border;
  border-left-width: 0;
  color: $primary;
  font-weight: 500;
  text-align: center;
  background: $tbl-bg;
}
.mat-paginator-container {
  background: $white-clr !important;
}
mat-cell.mat-cell {
  border: 1px solid $tbl-border;
  border-top: 0;
  color: $tbl-clr;
  border-right: 0;
  text-align: center;
}

mat-cell.mat-cell:first-child {
  border-left: 0;
}

.mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
  width: 1% !important;
}

.mat-cell,
.mat-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  line-break: normal;
  width: 10%;
  white-space: break-spaces;
  overflow: hidden;
  vertical-align: middle;
  line-height: normal;
}
.mat-header-cell {
  white-space: nowrap;
}

.geo_tbl mat-table ul.action {
  justify-content: center;
}

.tables ul.action {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 0;
}

.mat-table .btn {
  font-size: 13px;
  min-height: 8px;
  padding: 4px;
}

.tables ul.action li i {
  cursor: pointer;
  border-radius: 2px;
  padding: 2px;
  height: 15px;
  width: 15px;
  text-align: center;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $primary;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $primary;
}
.w_49 {
  width: 49%;
}
.pncl i {
  color: $white-clr !important;
  padding: 7px !important;
  font-size: 16px;
}

.eye i {
  color: $white-clr !important;
  padding: 7px !important;
  font-size: 16px;
}

.form_feilds {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  .mat-checkbox-frame {
    border: 1px solid $white-clr;
  }
}

.form_feilds .form_grup {
  width: 49%;
  padding-bottom: 20px;
}

.form_feilds mat-form-field.mat-form-field {
  width: -webkit-fill-available;
}

.form_grup.mps {
  width: 100%;
}

.form_feilds .mat-form-field-wrapper {
  padding-bottom: 0;
}
.template img {
  height: 400px;
  width: 100%;
  object-fit: contain;
}
.form_feilds .mat-form-field-infix {
  padding: 0;
  border-top: 0;
}
.screen-img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.form_grup mat-label {
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  color: $label-clr;
}

.mat-select-arrow-wrapper {
  transform: unset !important;
}

.prfl_img {
  height: 45px;
  width: 45px;
  margin: 0 auto;
}

.prfl_img img {
  border-radius: 0;
  height: 100%;
}

.pfl_mg {
  width: 100%;
  margin-bottom: 35px !important;
}

.pfl_inr figure {
  display: flex;
  overflow: visible;
  justify-content: center;
  border: 1px solid $primary;
  width: 150px;
  height: 150px;
  border-radius: 6px;
  position: relative;
  padding: 10px;
}

.pfl_inr figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.pfl_inr figure .camera {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: -15px;
  z-index: 0;
}

.pfl_inr figure input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}

.title-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mat-tab-label {
  margin-right: 5px;
  color: $primary;
  opacity: 1 !important;
  border: 0;
  background-color: rgb(239 239 239 / 0%);
  border-radius: 6px;
}

.mat-tab-label-active {
  color: $white-clr;
  background-color: $primary;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}

.form_feilds .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0;
}

.form_feilds .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
}

.form_feilds .mat-form-field-wrapper {
  padding-bottom: 0;
}

.form_feilds .mat-form-field-underline {
  display: none;
}

.mat-input-element.form_control,
.mat-form-field.form_control,
.form_control,
.ngx-mat-tel-input-container {
  border: 1px solid rgba(120, 141, 180, 0.3);
  border-radius: unset;
  font-size: 14px;
  font-weight: 400;
  color: $primary;
  background: transparent;
  width: -webkit-fill-available;
  padding: 5px 15px;
  background: none;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
  height: 36px;
}

.country-selector {
  left: 31px !important;
  top: 36px !important;
  bottom: inherit !important;
}
.toggle {
  cursor: pointer;
  span {
    background: $white-clr;
    height: 4px;
    display: block;
    margin: 5px 0;
    border-radius: 15px;
    transition: 0.3s all;
    &:nth-child(1) {
      width: 30px;
    }
    &:nth-child(2) {
      width: 20px;
    }
    &:nth-child(3) {
      width: 10px;
    }
  }
  &:hover span {
    width: 30px;
    transition: 0.3s all;
  }
}
.country-selector-code {
  margin-right: 8px !important;
}

.mat-form-field-suffix {
  position: absolute !important;
  top: 0;
  right: 0px;
}

.pasd_flds .mat-form-field-suffix {
  top: 7px;
}

.pasd_flds .pass_ico {
  margin-right: 6px;
  cursor: pointer;
  background-color: $white-clr;
}

.wcheck .mat-checkbox-layout .mat-checkbox-label {
  color: $white-clr;
}

.wcheck .mat-checkbox-inner-container {
  border: none;
  border-radius: 0px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $primary !important;
  padding: 0;
  border: 1px solid $white-clr;
  border-radius: 2px;
}

div#page-content-wrapper {
  padding: 20px;
}

.mat-drawer-container {
  background-color: $white-clr;
  color: $label-clr;
}

.faq_popup {
  width: 500px;
}
.prm_pop_mn {
  width: 700px;
}
.w-100 {
  width: 100% !important;
}

.faq_popup .form_feilds,
.prm_pop_mn .form_feilds {
  padding-top: 0;
}

.faq_popup .form_grup {
  width: 100%;
}
.limit_set h3 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
  color: $black-clr;
}
.limit_set .form_feilds {
  padding-top: 10px;
}
.faq_popup mat-select {
  border: 1px solid $border;
  border-radius: unset;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  color: $black-clr;
  background: transparent;
  width: -webkit-fill-available;
  border-radius: 0px;
}
.profile-upload .upload-img {
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  border-radius: 4px;
  position: relative;
}
.profile-upload figure {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.profile-upload figure img {
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile-upload .uploadfile {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.profile-upload .uploadfile input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  position: relative;
}
.tme {
  position: relative;
  .country-selector {
    left: 4px !important;
    top: 45px !important;
    bottom: inherit !important;
  }
}

.dash-cards ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px 0px;
}

.dash-cards ul li {
  width: 24%;
}
.mat-progress-bar-fill::after {
  background-color: $primary;
}
.dash-cards ul li .report-box {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  flex-wrap: wrap;
  border-radius: 15px;
  background-color: $white-clr;
  box-shadow: 0 0 9px $shadow;
  border: 1px solid $border;
}
.dash-cards ul li .report-box .rb-left {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.main-flx-box {
  display: flex;
  align-items: center;
  gap: 15px;
}
.rb-left figure {
  width: 35px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.rb-left figure img {
  filter: brightness(0);
}
.logo figure {
  margin: 0 auto 30px;
  width: fit-content;
}
.upld_doc.upld_bnr li {
  width: 100%;
  max-width: 1170px;
  p {
    font-size: 12px;
  }
  figure {
    width: 100%;
    height: 540px;
    background: $border;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.cnt_bx {
  width: 70%;
}

.cnt_bx p {
  margin-bottom: 0;
  color: $black-clr;
  font-weight: 400;
}

.dash-cards ul li .report-box .rb-left h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-right: 6px;
  color: $primary;
  margin-top: 5px;
}

.dash-cards ul li .report-box .rb-right {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  flex-direction: column;
}

.rb-right .mat-progress-bar {
  height: 7px;
  border-radius: 30px;
}

.dash-cards ul li .report-box .rb-right h4 {
  color: $black-clr;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
  margin-top: 5px;
}

ul li:nth-child(1) .report-box .rb-right .mat-progress-bar-fill::after {
  background: $primary;
}
.mat-progress-bar-buffer {
  background-color: #cbd0e9;
}
.usr_img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.mat-tab-labels {
  width: max-content;
  background: $white-clr;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(120, 141, 180, 0.18);
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}
.mat-tab-label-active {
  color: $white-clr;
  background-color: $primary;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.mat-tab-body-wrapper {
  margin-top: 20px;
}
.doc_view {
  margin-top: 20px;
}
.doc_view li {
  display: inline-block;
  margin-right: 20px;
}
.doc {
  width: 220px;
  height: 150px;
  margin-top: 10px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    cursor: pointer;
  }
}
.mat-tab-nav-bar,
.mat-tab-header {
  border: none;
}
.usr_dtl {
  display: flex;
  align-items: flex-start;

  .user_lhs {
    width: 20%;
    min-width: 265px;

    figure {
      width: 200px;
      height: 200px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .user_rhs {
    width: 78%;

    .view_details {
      display: flex;
      flex-wrap: wrap;
      .viewdetails_lyt,
      .viewdetails_ryt {
        width: 50%;
        .info label {
          font-size: 16px;
          color: #5e5e5e;
          font-weight: 400;
        }
        .info p {
          font-weight: 500;
          font-size: 15px;
          color: $primary;
        }
      }
    }
  }
}
.filter .mat-form-field {
  margin-left: 25px;
}
.tt_blnc {
  background: $white-clr;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 321px;
  margin-bottom: 20px;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
}
.tt_bx p {
  margin-bottom: 0;
}
.tt_bx h3 {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 10px;
}
// modal css
.modal_title {
  margin-bottom: 40px;
  h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
}
.usr_cnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  i {
    font-size: 20px;
    cursor: pointer;
  }
}
.usr_lf {
  display: flex;
  align-items: center;
  gap: 16px;

  h2 {
    font-size: 18px;
    margin-bottom: 0;
  }
}
.bx_sn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send_trns h4 {
  font-size: 18px;
  color: #7c7c7ca3;
  font-weight: 500;
}
.amnt h3 {
  font-size: 24px;
  font-weight: 500;
}
.amnt {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 20px;
}
.usr_profile {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;

  figure {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 10px;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    input {
      position: absolute;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      font-size: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      cursor: pointer;
    }
  }
}
.btn_grp {
  margin-top: 50px;
  button {
    width: 150px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    margin-right: 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: $white-clr;
  }
  .approved {
    background: $primary;
  }
  .reject {
    background: red;
  }
}
.mat-expansion-panel-header {
  padding: 0 20px 0 0 !important;
}
.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}
.form_grup .mat-select-trigger {
  height: 47px;
  border: 1px solid #d6dde8;
  border-radius: 5px;
  padding: 0 15px;
}
.form_grup label {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #949ea5 !important;
  padding: 10px 10px;
}
.form_grup span {
  color: $black-clr;
  font-size: 14px;
  font-weight: 500;
}
.slc-dt {
  display: flex;
  align-items: center;
  gap: 5px;
  .mat-form-field-flex {
    background: none;
    height: 30px;

    input {
      height: 28px;
      padding: 0px 7px;
    }
  }
  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
}
.mat-form-field-infix {
  background: $white-clr;
  border-radius: 5px;
}
.form_grup .country-selector {
  opacity: 1 !important;
}
.ngx-mat-tel-input-container input {
  padding: 10px 10px 10px 85px !important;
}
.mt_20 {
  margin-top: 20px;
}
.form_grup.w_100,
.form_grup.w_100 .prm_pop_mn .mat-select {
  width: 100%;
}
.mat-menu-content {
  padding: 0 !important;
}
.hd_menu {
  li {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  figure {
    width: 18px;
    img {
      filter: brightness(0);
    }
  }
}
textarea {
  min-height: 50px;
  padding: 10px;
}
.slc_ap {
  justify-content: center;
  align-items: center;
}
.slc_ap .form_grup {
  padding-bottom: 0;
}
.slc_ap .mat-select {
  min-width: fit-content;
}
.slc_ap .mat-select-trigger {
  border: none;
}
.slc_ap .mat-form-field-infix {
  width: 120px;
}
.card .card-body {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: none;
    padding: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-infix {
    border-top: none;
    padding-bottom: 0;
  }
}
.card .card-body {
  .mat-form-field-prefix .mat-icon,
  .mat-form-field-suffix .mat-icon {
    font-size: 150%;
    line-height: 33px;
  }
}
.w_max .mat-form-field {
  max-width: 100px;
}
.sb_hd {
  margin-top: 15px;

  h2 {
    font-size: 18px;
    color: $black-clr;
  }
}
.doc_area {
  display: flex;
  align-items: center;
  margin-top: 30px;

  li figure {
    width: 200px;
    height: 200px;
    margin-right: 30px;
  }
}
.upld_doc {
  display: flex;
  align-items: center;
  margin: 10px 0 30px;

  li figure {
    width: 100px;
    height: 100px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6dde8;
    border-radius: 14px;
    position: relative;

    input {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      font-size: 0;
      opacity: 0;
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.mr-2 {
  margin-right: 0.5rem;
}

.sb_admin .mat-cell:first-of-type {
  width: 7% !important;
}
@media (max-width: 1600px) {
  .mat-drawer.mat-drawer-opened {
    width: 20% !important;
  }
}
@media (max-width: 1200px) {
  .mat-list-item h5 {
    font-size: 12px;
  }
  .sd_cn {
    width: 16px;
    height: 16px;
  }
  .mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    padding: 0 8px !important;
  }
  .rb-left figure {
    width: 40px;
    height: 40px;
  }
  .btn.btn-primary {
    font-size: 14px;
  }
}
.aglin_btns {
  display: flex;
}
.filter_btn {
  display: flex;
  gap: 14px;
  .mat-form-field-infix {
    display: flex;
    align-items: center;
    border: 1px solid rgba(120, 141, 180, 0.3);
    width: 245px !important;
    border-top: 1px solid rgba(120, 141, 180, 0.3) !important;
    mat-date-range-input {
      padding-left: 12px;
      input {
        &::-webkit-input-placeholder {
          padding-top: 20px !important;
        }
      }
    }
  }
}
.dash-cards ul li.black_box .report-box {
  background-color: #000;

  h3,
  p {
    color: #fff !important;
  }
}

.srch {
  .mat-input-element {
    caret-color: #000;
  }
  &.mat-form-field {
    .mat-form-field-prefix {
      button {
        display: inline-flex;
        width: 25px;
        padding: 0;
        .mat-icon {
          position: relative;
          top: -3px;
          left: -1px;
        }
      }
    }
    .mat-form-field-infix {
      padding: 0;
    }
    .mat-form-field-label-wrapper {
      padding: 0;
      top: 0;
      label {
        top: 0;
      }
    }
  }
  &.mat-form-field.mat-focused .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }
}

.swal2-container {
  .logout_dialog {
    width: 350px;
    padding: 40px 40px;
    .swal2-icon.swal2-info.swal2-icon-show {
      border-color: #000;
      color: #000;
      margin-top: 0;
    }
    .swal2-title {
      font-size: 22px;
      color: #000;
      padding: 10px 0 0;
      margin-bottom: 10px;
    }
    .swal2-html-container {
      color: #000;
      margin: 0;
      font-size: 16px;
    }
    .swal2-actions {
      margin-top: 20px;
      button {
        height: 45px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0 25px;
      }
    }
  }
}

.login-page .pasd_flds .mat-form-field-suffix {
  top: 12px;
}
.btnDiabled .btn.btn-primary {
  pointer-events: none;
  cursor: not-allowed;
  background: gray;
}

.dialog_main .title-bar h1 {
  margin: 0px;
}
.dialog_main {
  position: relative;
}
.filter_lft i {
  position: absolute;
  right: -11px;
  top: -19px;
}
.dialog_vehicle .title-bar h1 {
  margin: 0px;
}
.map_mn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.map_mn li {
  width: 46%;
  box-shadow: 0 0 9px #e5e5e5;
  padding: 10px;
}
.map_mn li label {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  display: block;
  text-align: left;
}
.attr-val.att_nw {
  .mat-slide-toggle-thumb {
    background-color: red;
  }
  .mat-slide-toggle-bar {
    background-color: #ff0000a1;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #008000b8;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #008000b8;
  }
}

.add_merchant_field {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;

  mat-label {
    font-weight: 400;
    font-size: 14px;
    color: #222;
  }
  .bx_allign {
    width: 100%;
    display: block;
    .cust_row {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
  .photo_grp {
    width: 100px;
    height: 100px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100px;
      width: 100%;
      cursor: pointer;
      font-size: 0;
      opacity: 0;
    }
  }
  .addbox_body {
    .prfl_pic {
      width: 100px !important;
      height: 100px !important;
      margin-bottom: 0 !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        padding: 0;
        &[src*='add'] {
          max-width: 52px;
          margin: auto;
        }
      }
      &.prf_cmra {
        position: absolute;
        inset: 0;
        opacity: 0;
        z-index: 1;
      }
    }
  }
}

.mat-table .form_grup .btn,
.mat-table mat-cell > .btn {
  padding: 15px;
  min-width: 60px;
}

mat-drawer-container.mat-drawer-container.right_fixed_side {
  position: fixed !important;
  inset: 0;
  z-index: 999;
  visibility: hidden;
  background-color: transparent;
  &.mat-drawer-container-has-open {
    visibility: visible;
  }
}

.mat-drawer.invoice_temp {
  top: 60px;
  position: fixed;
  z-index: 99999;
  width: 100% !important;

  min-height: calc(100vh - 60px);
  max-width: 500px;
  .mat-drawer-inner-container {
    width: 100% !important;
    padding: 40px 20px 30px;
    box-sizing: border-box;
    background: #fff;
    margin-left: auto;
    position: relative;
  }
  .pop_mn {
    box-shadow: unset;
    border: 0;
    .card-body {
      padding: 0;
      > h2 {
        margin-bottom: 20px;
      }
    }
    > h3 {
      font-size: 18px;
      line-height: normal;
      font-weight: 700;
      color: #1d1d1d;
    }
  }
  .invoice_mn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
    .invoice_lft,
    .invoice_ryt {
      width: 48%;
    }
  }
  .invoice_ryt {
    h3 {
      line-height: normal;
      margin-bottom: 10px;
      font-size: 16px;
      color: #1d1d1d;
    }
    p {
      line-height: normal;
      font-size: 13px;
      color: #1d1d1d;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
    &.w_100 {
      width: 100%;
    }
  }
  .btn_close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;

    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background: #000;
      color: #fff;
    }
    .mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
  .order_item {
    counter-reset: my-sec-counter;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-left: 20px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #d6dde880;
      &:not(.footer_dtl) {
        &::before {
          counter-increment: my-sec-counter;
          content: counter(my-sec-counter) '.';
          line-height: normal;
          align-self: flex-start;
          position: absolute;
          left: 0;
          top: 0px;
        }
      }

      p {
        font-size: 13px;
        font-weight: 400;

        &.price {
          color: #ff0000;
          font-weight: 600;
        }
      }
      .prdct_dtl {
        span:not([matTooltip]) {
          font-weight: 700;
          white-space: nowrap;
        }
      }
      &.footer_dtl {
        padding-left: 0;
        p {
          margin: 0;
        }
        .prdct_dtl {
          span {
            font-weight: 500;
          }
        }
        h4 {
          margin: 0;
          line-height: normal;
          font-size: 14px;
          span {
            font-size: 15px;
            font-weight: 400;
          }
          &::first-letter {
            text-transform: uppercase;
            word-break: break-word;
          }
        }
      }
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
  }
  .form_grup {
    align-self: flex-start;
    .mat-select-trigger {
      height: auto;
      min-height: 35px;
    }
  }
}
body:has(.invoice_temp.mat-drawer-opened) {
  overflow: hidden;
  .mat-drawer-content {
    overflow: hidden;
  }
  .mat-tab-header {
    z-index: 0;
  }
  .top_header_ryt.end_pop {
    display: none;
  }
}
.mat-select-panel .mat-option[aria-disabled='true'] {
  cursor: not-allowed;
  opacity: 0.6;
}
.mat-drawer-container.right_fixed_side .mat-drawer-backdrop.mat-drawer-shown {
  pointer-events: none;
}

.redClass {
  color: $red-clr !important;
}
.filter_sec bazaarna-search .filter_lft {
  margin: 0;
}
.main-flx-box {
  .form_grup .mat-select-trigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #d6dde8 !important;
    border-radius: 5px;
    padding: 0 15px;
  }
  .mat-select-arrow-wrapper {
    display: block !important;
    transform: translateY(40%) !important;
  }
}
