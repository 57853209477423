$primary: #1d1d1d;
$black-clr: #000;
$para-clr:#5d5d5d;
$white-clr:#fff;
$yellow-clr:#ff9800;
$red-clr: red;
$green-clr:green;
$lightbg:#f1f2f6;
$border: rgba(120, 141, 180, 0.3);
$tbl-bg:#e5dcf652;
$tbl-clr:#292929;
$tbl-border:rgb(229 220 246);
$label-clr:#617182;
$shadow:#e5e5e5;
$font_family:'Stolzl', sans-serif;

// $gradiant_color1:linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
